import React, {useEffect} from "react"
import Seo from "../../components/Core/Seo"
import Layout from "../../components/Layout/Layout"
import colors from "../../styles/colors"
import { isBrowser } from "../../services/browser"

const AccountPage = ({ location }) => {
  const path = location.pathname

  useEffect(() => {
    if(isBrowser()) {
        window.location.href = "/account/orders"
    }
  }, [])
  

  return (
    <Layout dark bg={colors.accountGrey}>
      <Seo
        title="Account Page"
        description="In Good Company Account Page"
        fullTitle="Account Page"
        path={path}
      />
      <div></div>
    </Layout>
  )
}

export default AccountPage
